import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      {
        name: 'title',
        children: (
          <span>
            <p>崇杏客户&nbsp;</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>我们致力于对患者、医护、各级医院、卫健委/申康发展中心提供最优质的服务&nbsp;</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer1.PNG',
          },
          content: { children: '上海肺科医院' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer2.PNG',
          },
          content: { children: '上海市公利医院' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer3.PNG',
          },
          content: { children: '上海市浦东医院' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer4.PNG',
          },
          content: { children: '上海市第十人民医院' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer5.PNG',
          },
          content: { children: '上海市五官科医院' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer6.PNG',
          },
          content: { children: '上海新华医院' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer7.PNG',
          },
          content: { children: '上海市胸科医院' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer8.PNG',
          },
          content: { children: '上海市周浦医院' },
        },
      },
      {
        name: 'block8',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer9.PNG',
          },
          content: { children: '上海市宝山中西医结合医院' },
        },
      },
      {
        name: 'block9',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer10.PNG',
          },
          content: { children: '上海市大场医院' },
        },
      },
      {
        name: 'block10',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer11.PNG',
          },
          content: { children: '上海市第一人民医院' },
        },
      },
      {
        name: 'block11',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer12.PNG',
          },
          content: { children: '上海市奉贤中心医院' },
        },
      },
      {
        name: 'block12',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer13.PNG',
          },
          content: { children: '复旦大学附属中山医院青浦分院' },
        },
      },
      {
        name: 'block13',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer14.PNG',
          },
          content: { children: '上海市奉贤区古华医院' },
        },
      },
      {
        name: 'block14',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer15.PNG',
          },
          content: { children: '上海市北站医院' },
        },
      },
      {
        name: 'block15',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://api.highwho.com/static/homepage/img/customer16.PNG',
          },
          content: { children: '上海市松江中心医院' },
        },
      },
    ],
  },
};
