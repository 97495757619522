import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      {
        name: 'title',
        children: (
          <span>
            <p>联系我们&nbsp;</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>地址：上海市嘉定区金园五路601号2号楼2楼</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
            <p>联系电话：15301765000 / 18616655005</p>
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content4',
        children: (
          <span>
            <p>
            <p>联系微信1：Cxjk-001（崇小杏）</p>
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content5',
        children: (
          <span>
            <p>
            <p>联系微信2：Cxjk-120（崇杏健康）</p>
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content3',
        children: (
          <span>
            <p>
            <p>Email：connect@highwho.com</p>
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 0, xs: 0 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <p>地址：上海市嘉定区金园五路601号2号楼2楼</p>
                <p>
                  电话：18616655005 18964456571<br />
                </p>
                <p>
                  Email：connect@highwho.com&nbsp;<br />
                </p>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Contact00DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://ditu.amap.com/place/B0FFJP3P70', name: '跳转地址' },
      title: { children: '地址', name: '弹框标题' },
      content: {
        children: '上海市嘉定区金园五路601号2号楼2楼',
        name: '弹框内容',
      },
    },
  },
};
