import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '崇杏产品', className: 'title-h1' },
      {
        name: 'content',
        children:
          '医疗服务监管平台、医患管理系统、医疗服务数据分析',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '',
        className: 'title-content',
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://api.highwho.com/static/homepage/img/product1.PNG',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '医疗服务监管平台' },
  content: {
    className: 'content1-content',
    children:
      '采用智能电子标签、移动互联网等数字化技术，通过多媒体信息收集和上报手段，实现对各医疗机构服务评测、服务评价、服务投诉、安全隐患、医疗纠纷、舆情管理、服务监督和服务考核的分层分级的管理和处理，达到“预防为主、群防群控的”的管理思想，为提升群众就诊体验和各医疗机构的医疗服务水平提供技术保障。',
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://api.highwho.com/static/homepage/img/product2.PNG',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: '医患管理系统' },
  content: {
    className: 'content2-content',
    children:
      '我们致力于在医疗机构，医务人员和患者间的医疗服务中建立关心爱护的交流渠道，为医患健康守护、患者安全促进，患者健康素养提高，为构建优质、安全、满意、和谐的医患关系赋能。 ',
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://api.highwho.com/static/homepage/img/product3.PNG',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '医疗服务数据分析' },
  content: {
    className: 'content1-content',
    children:
      '医院服务数据分析是集预约、接待、导医、便民、咨询、随访、投诉、督查、问卷、安全和网络舆情监管为一体的，面向患者、医务人员和医院的服务和服务调配中心，通过再造服务流程，加强医务人员服务监管和考核，提供患者和医务人员人文关怀服务，及时沟通和解决患者诊疗过程中碰到的困难和问题，加强网络舆情监管和正面引导。提高医务人员服务意识、自觉性和敬业度，降低患者不满情绪和投诉量，提高患者医疗服务体验值和满意度，让广大人民群众自觉成为医院良好名誉和声誉传播者和维护者，形成正向舆情引导，从而营造一个良好的舆论环境。',
  },
};
