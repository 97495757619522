import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>崇杏服务(本页升级中...)</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>医疗服务监管平台、医患管理系统、医疗服务数据分析等各项服务价格</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <p>
                <br />
              </p>
            </span>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Pricing20DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>患者友好度系统价格</p>
            </span>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>贴码数量</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'free~kds6ust90cc',
          key: 'free~kds6ust90cc',
          name: 'free~kds6ust90cc',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>系统收费</p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>0&lt;数量&lt;=1000</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>9.8万（上海）</p>
                </span>
              ),
              name: 'content~kds6ust9b9n',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>1001&lt;数量&lt;=2000</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>15.8万（上海）</p>
                </span>
              ),
              name: 'content~kds6ust9z9f',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>2001&lt;数量&lt;=4000</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds6ust95ea',
              children: (
                <span>
                  <p>19.8万（上海）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              children: (
                <span>
                  <p>数量&gt;4001,或者全区部署</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>29.8万（上海）</p>
                </span>
              ),
              name: 'content~kds6ustakdr',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>0&lt;数量&lt;=1000</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds6ustal9p',
              children: (
                <span>
                  <p>9.8万（上海以外地区）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>1001&lt;数量&lt;=2000</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds6ustatvs',
              children: (
                <span>
                  <p>15.8万（上海以外地区）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~kds72vb3g2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>2001&lt;数量&lt;=4000</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds6ustatvs',
              children: (
                <span>
                  <span>
                    <p>25.8万（上海以外地区）</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~kds72wrt199',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>4001&lt;数量&lt;=10000</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds6ustatvs',
              children: (
                <span>
                  <p>39.8万（上海以外地区）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Pricing21DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>患者友好度系统按年支付价格</p>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>贴码数量</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>系统收费</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>服务内容</p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>0&lt;数量&lt;=1000</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>2万/年</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>3年起签</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <p>1001&lt;数量&lt;=2000</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>3万/年</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>3年起签</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>数量&gt;2001</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>5万/年</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>3年起签</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Pricing22DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>工会系统价格</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>系统&nbsp;</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>系统收费</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>服务内容</p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>工会系统</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>9.8万（上海）</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>
                    含三次免费上门服务，含一次系统升级。次年起每年收取10%服务费。
                  </p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <p>订餐系统</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>9.8万（上海）</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>
                    含三次免费上门服务，含一次系统升级。次年起每年收取10%服务费。
                  </p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>工会系统</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <span>
                    <span>
                      <p>19.8万（上海以外地区）</p>
                    </span>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>
                    含三次免费上门服务，含一次系统升级。次年起每年收取10%服务费。
                  </p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              children: (
                <span>
                  <p>订餐系统</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>19.8万（上海以外地区）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>
                    含三次免费上门服务，含一次系统升级。次年起每年收取10%服务费。
                  </p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Pricing23DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>数据分析价格&nbsp;</p>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>医院级别</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>
                      报表类型<span>&nbsp;</span>
                    </p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>价格&nbsp;</p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>一级</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>基本报表</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>免费</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <p>二级</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>基本报表</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>免费</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>三级</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>基本报表</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>免费</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              children: (
                <span>
                  <p>一级</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>规定格式的报表</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>500元/份/月（按年签，提供年终工作报告一份）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>二级</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>规定格式的报表</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>500元/份/月（按年签，提供年终工作报告一份）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>三级</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>规定格式的报表</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>500元/份/月（按年签，提供年终工作报告一份）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~kds7y4w0tsm',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>一级</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>自定义报表</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>1000元/份/月（按年签，提供年终工作报告一份）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~kds7y60z1s8',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>二级</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>自定义报表</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>1000元/份/月（按年签，提供年终工作报告一份）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~kds7y6kl2x',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>三级</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>自定义报表</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>1000元/份/月（按年签，提供年终工作报告一份）</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Pricing24DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>崇杏云健康码&nbsp;</p>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>健康码</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>单价</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>起订数量</p>
                        </span>
                      </span>
                    </span>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>覆膜不干胶</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>5元/张</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>100</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <p>亚克力板</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>15元/片</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>100</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>带nfc芯片的亚克力板</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>20元/片</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>100</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Pricing25DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>满意度服务费</p>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>项目</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>备注</p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>科室信息录入（更替）</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>提供为期1年的基础科室信息更替服务</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <p>满意度联络员绑定信息更换</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>提供为期1年的满意度联络员信息更换服务</p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <span>
                      <p>问卷信息修改</p>
                    </span>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>提供为期1年的问卷格式调整服务</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              children: (
                <span>
                  <p>部署信息的修改及部署换的动态的测试</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>提供为期1年的电子标签信息更替服务及动态稳定性测试</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>电脑端账号租赁及维护</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>提供为期1年的PC管理端账号维护</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>移动手机端账号租赁及维护</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>提供为期1年的手机管理端账号维护</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~kds86t2bxut',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>合计费用：</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <span>
                    <p>40000元</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Pricing26DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>满意度服务费</p>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>选项</p>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <p>金额</p>
                    </span>
                  </span>
                ),
              },
            ],
          },
        },
        {
          dataIndex: 'pro~kds895y5rr8',
          key: 'pro~kds895y5rr8',
          name: 'pro~kds895y5rr8',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>备注</p>
                  </span>
                ),
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>科室信息录入（更替）</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>300元</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>基础科室信息更替服务/次</p>
                </span>
              ),
              name: 'content~kds895y5gv',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <p>满意度联络员绑定信息更换</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>100元</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>满意度联络员信息更换服务/1个</p>
                </span>
              ),
              name: 'content~kds895y5tzl',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>问卷信息修改</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>100元</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds895y54pa',
              children: (
                <span>
                  <p>问卷格式调整服务/套</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>部署信息的修改及部署换的动态的测试</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <span>
                    <p>100元</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds895y5mtp',
              children: (
                <span>
                  <p>电子标签信息更替服务及动态稳定性测试/个</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>电脑端账号租赁及维护</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>1500元</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds895y5zhc',
              children: (
                <span>
                  <p>PC管理端账号维护/月</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>移动手机端账号租赁及维护</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>1500元</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~kds895y5gp6',
              children: (
                <span>
                  <p>手机管理端账号维护/月</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
